export default {
  text: '#0c1b2a',
  text2: '#0c1b2a',
  primary: '#5b88ab',
  secondary: '#284ac6',
  background: 'white',
  backgroundPrimary: '#5b88ab',
  backgroundSecondary: '#284ac6',
  backgroundTertiary: '#284ac6',
  light: '#FFF',
  dark: '#0c1b2a'
}

export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  locationsPage: {
    '.locationTitle': {
      variant: 'customVariants.title',
      color: 'white',
      fontSize: ['2rem', '2rem', '2.25rem', '2.75rem', '3rem']
    },
    CTAS: {
      marginTop: '1.5rem'
    },
    '.CTAButton': {
      display: 'none'
    },
    '.enterSiteButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    'li a': {
      fontFamily: 'body!important'
    },
    '.smallNavMenuRight': {
      border: 'none!important'
    },
    '.logoLocationContainer': {
      position: 'static',
      margin: '0rem auto 0rem 0rem'
    },
    '.logo': {
      //   backgroundColor: 'white',
      borderRadius: '100%',
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      width: ['', '', 190],
      height: ['', '', 190],
      img: {
        maxHeight: ['120px', '', '180px', ''],
        filter: 'unset',
        padding: '0rem'
      }
    },
    '.logoScrolled': {
      //   backgroundColor: 'white',
      borderRadius: '100%',
      display: 'inline-block',
      img: {
        padding: '0rem',
        maxHeight: ['90px', '', 130],
        filter: 'unset'
      }
    },

    '> div > .container': {
      padding: ['.5rem', '', ''],
      position: 'absolute',
      backgroundColor: 'primary',
      top: '0rem',
      color: 'white',

      '.smallNavMenu': {
        alignItems: 'center',
        // margin: '0rem auto 0rem 2rem',
        '.navItem': {
          a: {
            fontSize: '1rem',
            fontFamily: 'body!important',
            color: 'white',
            ':hover': {
              textDecoration: 'underline'
            }
          }
        },
        '.react-reveal': {
          border: 'none!important',
          opacity: '1'
        }
      }
    },
    '.containerScrolled': {
      border: 'none',
      padding: '0.5rem 1rem',
      backgroundColor: 'primary',
      // width: 'fit-content',
      position: 'fixed',
      right: '0rem',
      top: '0rem',
      left: 'unset',
      borderBottom: '1px solid white',
      '.smallNavMenu': {
        border: 'none',
        alignItems: 'center'
      },
      '.react-reveal': {
        border: 'none!important',
        opacity: '1'
      },

      '.navItem': {
        '.react-reveal': {
          border: 'none!important',
          opacity: '1'
        },
        border: 'none',
        a: {
          color: 'white',
          border: 'none',
          fontFamily: 'body!important'
        }
      }
    },
    '.hamburger': {
      borderRadius: '100px',
      borderColor: 'primary',
      display: ['', '', '', 'none'],
      '> div': {
        backgroundColor: 'white'
      }
    },

    '.phoneContainer': {
      display: 'flex',
      border: 'none',
      margin: '0rem 1rem',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      a: {
        backgroundColor: 'primary',
        padding: '0.5rem',
        color: 'light',
        textAlign: 'center',
        margin: '0.5rem 0rem'
      }
    },
    '.hamburgerOpen': {
      borderColor: 'white',
      '> div': {
        backgroundColor: 'primary'
      }
    },
    '.navMenuLogo': {
      display: 'none'
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'primary',
      '.navItem': {
        a: {
          color: 'light',
          fontFamily: 'body!important',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'white'
      },

      '.socialIconsContainer': {
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          fontFamily: 'body!important',
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'white'
            }
          }
        }
      }
    },

    '.navBlock': {
      right: 'unset',
      top: '200vh'
    },

    '.locationSwitcher': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    // Location switchers
    '.locationSwitcherV1': {
      padding: '0rem',
      button: {
        border: 'none',
        borderTop: 'lightgrey 1px solid',
        fontSize: '0.8rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        padding: '0.25rem',
        svg: {
          marginRight: '0.25rem'
        },
        ':hover': {
          backgroundColor: 'primary',
          color: 'white'
        }
      },

      '.popUp': {
        transform: ['unset', 'unset', 'unset', 'unset'],
        '.norwalk': {
          display: 'none'
        }
      }
    }

    // ! Bring this back if i want to change the color of the location switcher
    // '.containerScrolled': {
    //   '.locationSwitcherV1': {
    //     button: {
    //       color: 'white!important',
    //     },
    //   },
    // },
  },

  footer: {
    backgroundColor: 'dark',
    color: 'white',
    '.aboutText': {
      color: 'white'
    },
    '.multiButtonContainer a': {
      color: 'white'
    },
    '.ctaButton': {
      border: 'none',
      borderTop: 'lightgrey 1px solid',
      fontSize: '0.9rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0.5rem 0.75rem',
      svg: {
        marginRight: '0.25rem'
      },
      color: 'dark',
      backgroundColor: 'white'
    },
    '.gonationLogo': {
      order: '7', // make it last
      marginTop: '0.5rem',
      '.poweredByText': {
        justifyContent: 'flex-start',
        fontSize: '0.9rem',
        svg: {
          fill: 'white',
          maxWidth: '100px !important'
        }
      }
    },

    '.locationSwitcherV1': {
      display: 'none!important',
      padding: '0rem',
      button: {
        border: 'none',
        borderTop: 'lightgrey 1px solid',
        fontSize: '0.9rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.5rem 0.75rem',

        svg: {
          marginRight: '0.25rem'
        },
        color: 'black',
        backgroundColor: 'secondary',
        ':hover': {}
      },
      '.popUp': {
        // transform: ['unset', 'unset', 'unset', 'unset'],
        '.norwalk': {
          display: 'none'
        }
      }
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2.25rem', '2.5rem', '3.25rem', '3.5rem', '4rem'],
    fontWeight: 'bolder',
    letterSpacing: '4px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    fontFamily: 'heading',
    color: 'inherit'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1.5rem',
    border: 'none',
    color: 'text2',
    fontFamily: 'subheading',
    opacity: '0.8',
    fontWeight: '200'
  },
  text: {
    lineHeight: '1.75',
    color: 'inherit',
    fontSize: ['1rem', '', '1.1rem'],

    p: {
      color: 'inherit',
      lineHeight: '1.75',
      textAlign: 'left',
      fontSize: ['1rem', '', '1.1rem']
    }
  },

  sideBySide1: {
    height: ['', '', '85vh', '85vh'],
    backgroundColor: 'background',
    padding: ['1rem', '', '0rem', '0rem'],
    margin: '0rem',
    '.lazyload-wrapper': {
      minHeight: '50vh',
      justifyContent: 'flex-start',
      width: ['', '', '60%', '65%']
    },
    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      width: ['', '', '40%', '35%']
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'black'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  // centerBlock: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   minHeight: '90vh',
  //   color: 'white',
  //   textAlign: 'center',

  //   padding: ['6rem 1rem', '8rem 1rem'],
  //   '.title': {
  //     variant: 'customVariants.title',
  //   },
  //   '.subtitle': {
  //     variant: 'customVariants.subtitle',
  //   },

  //   '.text': {
  //     variant: 'customVariants.text',
  //   },
  //   a: {
  //     order: '4',
  //   },
  //   '.ctaButton': {
  //     variant: 'buttons.primary',
  //   },
  // },

  // centerBlock2: {
  //   variant: 'customVariants.centerBlock',
  //   background: 'none',
  //   height: 'auto',
  //   color: 'black',
  // },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    // backgroundColor: 'backgroundPrimary',
    marginTop: ['142px', '', '184px'],
    height: ['', '', '700px'],
    '.content': {
      width: ['100%', '', '50%'],
      color: 'text',
      backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/v1670874889/sites/massimo/graphic.png)',
      backgroundSize: ['40%', '', '50%'],
      backgroundPosition: '5% 95%',
      backgroundRepeat: 'no-repeat',
      order: ['2', '', '1'],
      paddingBottom: ['5rem', '', 'initial']
    },
    '.lazyload-wrapper': {
      width: ['100%', '', '50%'],
      order: ['1', '', '2']
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',

    background: `linear-gradient(
          rgba(0, 0, 0, 0.6),
          rgba(0, 0, 0, 0.6)
        ), url(https://res.cloudinary.com/gonation/image/upload/v1670875059/sites/massimo/tile.png)`,
    backgroundSize: '50%',
    backgroundRepeat: 'repeat',
    backgroundColor: 'backgroundTertiary',

    '.title, .subtitle, .text': {
      color: 'white'
    },
    '.subtitle': {
      maxWidth: '525px' // The customer requested to have a new line after a word. This is a hacky way to do it.
    },
    '.lazyload-wrapper': {
      width: ['', '', '35%', '50%'],
      img: {
        objectFit: 'contain'
      }
    },
    '.content': {
      width: ['', '', '65%', '70%'],
      padding: ['1rem', '2rem', '3rem', '4rem', '10rem 5rem']
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'primary',
    '.title, .text': { color: 'white' },
    '.ctaButton': {
      backgroundColor: 'dark',
      color: 'white'
    }
  },
  // homepageBoxes: {
  //   backgroundColor: 'transparent',
  //   padding: ['2rem', '', '', '2rem'],
  //   '.box': {
  //     width: ['100%', '', 'calc(50% - 3rem)', 'calc(33.3% - 3rem)'],
  //     margin: '1.5rem',
  //     border: 'solid 1px #efefef',
  //     transition: 'all ease-in-out 1s',
  //     borderRadius: '0px',
  //     ':hover': {
  //       border: 'solid 1px black',
  //       '.image': {
  //         filter: 'grayscale(100%)',
  //       },
  //       '.title': {
  //         borderBottom: 'solid 2px black',
  //       },
  //     },
  //     '.image': {
  //       height: ['auto', '', '350px'],
  //       objectFit: 'cover',
  //       transition: 'all ease-in-out 1s',
  //     },
  //     '.title': {
  //       variant: 'customVariants.title',
  //       fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem', '2.25rem'],
  //       transition: 'all ease-in-out 1s',
  //       borderBottom: 'solid 2px #efefef',
  //       paddingBottom: '0.5rem',
  //     },
  //     '.subtitle': {
  //       variant: 'customVariants.subtitle',
  //       fontWeight: '300',
  //     },
  //     '.text': {
  //       variant: 'customVariants.text',
  //       padding: '0rem 1rem',
  //     },
  //   },
  // },
  homepageGallery: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'primary',
    '.title, .text': { color: 'white' },
    '.ctaButton': {
      backgroundColor: 'dark',
      color: 'white'
    }
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'primary',
    '.title, .text': { color: 'white' },
    '.ctaButton': {
      backgroundColor: 'dark',
      color: 'white'
    }
  },
  homepageContact: {
    variant: 'customVariants.sideBySide1',
    '.ctaButton': {
      backgroundColor: 'dark',
      color: 'white'
    },

    backgroundColor: 'primary',
    '.title, .text': { color: 'white' },
    '.ctaButton': {
      backgroundColor: 'dark',
      color: 'white'
    }
  },

  homepageQuote: {
    //pending

    '.section': {
      'div:nth-of-type(1)': {
        order: '2'
      },
      'div:nth-of-type(2)': {
        order: '1'
      },
      'div:nth-of-type(3)': {
        order: '3'
      },
      'div:nth-of-type(4)': {
        order: '4'
      },
      '.title': {
        variant: 'customVariants.title',
        textShadow: '6px 6px 0px #5b88ab',
        textAlign: 'center'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        color: 'white'
      }
    }
  },

  homepageQuote1: {
    variant: 'customVariants.homepageQuote'
  },
  homepageQuote2: {
    variant: 'customVariants.homepageQuote'
  },

  homepageTestimonials: {
    padding: ['0rem', '0rem', '0rem'],
    maxWidth: 'unset',
    '.innerMenuContainer': {
      backgroundColor: 'background',
      // padding: ['1rem', '2rem 1rem', '3rem 2rem'],
      padding: ['1rem', '2rem', '4rem 4rem'],
      maxWidth: 'unset',
      position: 'relative'
    },
    '.menuSectionTitle': {
      '::before': {
        content: "'❝'",
        padding: '0rem 0.5rem'
      },
      variant: 'customVariants.title',
      border: 'none',
      textAlign: 'center',
      margin: '0 auto 1rem',
      width: '100%',
      justifyContent: 'center',
      '::after': {
        content: "'❞'",
        padding: '0rem 0.5rem'
      }
    },

    '.menuSectionDescription': {
      variant: 'customVariants.text',
      fontSize: '1rem'
    },

    '.menuItemNameContainer': {
      variant: 'customVariants.subtitle',
      order: '4',
      margin: '1rem auto 0rem',
      textAlign: 'center'
    },

    '.menuItemName': {
      fontSize: ['1rem', '1rem', '1rem', '1rem']
    },

    '.menuItemDescription': {
      variant: 'customVariants.text',
      color: 'primary',
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      padding: ['0rem', '', '0rem 1rem'],
      margin: '0 auto 1rem'
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto'
    }
  },

  homepageShout: {
    marginTop: ['192px!important', '', '', '262px!important'],
    backgroundColor: 'primary',
    marginBottom: '3rem',
    padding: ['1rem', '', '2rem 1rem', '3rem 2rem 10rem'],
    // borderTop: '1px solid',
    // borderBottom: '1px solid',
    flexWrap: ['wrap', 'wrap', 'wrap', 'wrap'],
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '3px solid',
      borderColor: 'text2',
      fontFamily: 'heading',
      width: ['', '', '80%'],
      color: 'white',
      backgroundColor: 'transparent',
      letterSpacing: '0px'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.75rem', '', '1.5rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      borderRadius: ['', '', '', '10px'],
      fontWeight: 'normal',
      padding: ['', '', '0rem 1rem'],
      backgroundColor: ['white'],
      color: 'dark',
      padding: ['2rem', '2rem', '1rem'],
      marginTop: '1rem',
      width: ['', '', '55%']
    },
    '.date': {
      backgroundColor: 'transparent',
      //   border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem',
      maxWidth: 'unset',
      color: 'white',
      flexGrow: '1',
      fontWeight: 'bold',
      justifyContent: 'flex-start',
      position: 'relative',
      left: ['', '', '', '0'],
      bottom: ['', '', '', '-1rem']
    },
    '.imageContainer': {
      position: 'relative',
      top: ['', '', '', '5rem'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer',
      flexGrow: '1'
    },

    margin: '0rem 0rem',
    '.shoutCTABtns ': {
      border: ['', '', '', 'solid 8px'],
      borderColor: ['', '', '', 'background'],
      flexDirection: ['row', 'row', 'row', 'column'],
      width: ['100%', '', '', 'auto'],
      a: {
        width: ['50%', '', '', '100%'],
        writingMode: ['unset', 'unset', 'unset', 'tb'],
        padding: '1rem',
        backgroundColor: 'transparent',
        color: 'primary',
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        ':hover': {
          backgroundColor: 'primary',
          color: 'white'
        }
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    backgroundColor: 'black!important',
    '.section': {
      maxWidth: '1200px!important'
    },
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'white'
    },
    '.subtitle': {
      color: 'white'
    },
    '.text': {
      color: 'white'
    }
  },

  bio: {
    variant: 'customVariants.sideBySide1',
    marginTop: '0rem',
    backgroundColor: '#e9e9e2',
    padding: '5rem 2rem 0rem',
    'div.content': {
      width: ['', '', '60%']
    },

    'div.lazyload-wrapper': {
      overflow: 'hidden',
      width: ['', '', '40%'],
      img: {
        objectFit: 'contain',
        objectPosition: 'top'
      }
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  // menu: {},
  menu: {
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '2.5rem'],
      textAlign: 'center',
      marginBottom: '1.5rem',
      borderColor: 'text2',
      fontFamily: 'display',
      color: 'primary',
      display: 'flex',
      justifyContent: 'center'
    },

    '.menuSectionDescription': {
      textAlign: 'center',
      marginBottom: '1.5rem',
      maxWidth: ['100%'],
      padding: '1rem',
      display: 'flex',
      justifyContent: 'center'
    },

    '.menuItemContainerImgActive': {
      height: '100%',
      '.menuItemInnerContainer': {
        flexDirection: ['column', '', 'row'],
        justifyContent: 'center',
        alignItems: 'center',
        '.menuItemContentContainer': {
          width: ['100%', '', '', '70%'],
          '.menuItemName': {
            variant: 'customVariants.title',
            fontSize: ['1.25rem', '1.5rem', '1.5rem', '1.5rem', '1.5rem'],
            color: 'dark'
            // width: ['60%', '75%'],
          },
          '.itemVariantsContainer': {
            display: 'flex',
            flexWrap: 'wrap'
          },
          '.variantsContainer': {
            display: 'flex',
            flexWrap: 'wrap',
            flexGrow: '1',
            margin: '0.5rem 1rem 0.5rem 0rem'
            // width: ['33.3%'],
          },
          '.variantContainer': {
            display: 'flex',
            width: '100%',
            fontFamily: 'body2',

            padding: '0.75rem',
            borderRadius: '10px'
          },
          '.menuItemPriceLabel': {
            fontWeight: 'bold',
            fontSize: '0.8rem',
            marginBottom: '0.25rem'
          },
          '.variantContainerFloatTopRight': {
            fontSize: ['1.25rem', '1.5rem', '2rem']
          },
          '.menuItemLabelOptions': {
            fontSize: '1rem',
            maxWidth: '350px'
          },
          '.menuItemPriceVariants': {
            fontSize: '1rem',
            maxWidth: '350px',
            color: 'text2'
          },
          '.menuItemDescription': {
            width: '100%',
            fontFamily: 'body2',
            // opacity: '1',
            fontSize: '1rem',
            lineHeight: '1.5'
          }
        },

        '.menuItemImageContainer': {
          width: ['100%', '', '', '30%'],

          '.menuItemImg': {
            // objectPosition: 'top',
          }
        }
      }
    },
    '.menuItemPageContent': {
      '.itemVariantsContainer': {
        display: 'flex',
        flexWrap: 'wrap'
      },
      '.variantsContainer': {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: '1',
        margin: '0.5rem 1rem 0.5rem 0rem'
        // width: ['33.3%'],
      },
      '.variantContainer': {
        display: 'flex',

        fontFamily: 'body2',

        padding: '0.75rem',
        borderRadius: '10px'
      },
      '.menuItemPriceLabel': {
        fontWeight: 'bold',
        fontSize: '0.8rem',
        marginBottom: '0.25rem'
      },
      '.variantContainerFloatTopRight': {
        fontSize: ['1.25rem', '1.5rem', '2rem']
      },
      '.menuItemLabelOptions': {
        fontSize: '1rem',
        maxWidth: '350px'
      },
      '.menuItemPriceVariants': {
        fontSize: '1rem',
        maxWidth: '350px'
      },
      '.menuItemDescription': {
        width: '100%',
        backgroundColor: 'white',
        opacity: '1',
        lineHeight: '1.7',
        padding: '1rem 0.25rem'
      }
    },
    '.menuItemButton': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    '.text': {
      color: 'white'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'primary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '1.75rem', '1.75rem', '1.75rem', '1.75rem'],
      alignItems: 'center',
      color: 'white'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.textContent': {
      backgroundColor: 'lightgrey'
    },
    '.imageContainer': {
      backgroundColor: 'white',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pageHero: {
    marginTop: ['', '', '184px']
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
